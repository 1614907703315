import React from 'react';

export default function Custom404() {
  React.useEffect(() => {
    setTimeout(() => {
      // window.location.href = '/';
    }, 2000);
  }, []);

  return (
    <div className="text-default bg-white h-screen text-center flex flex-row justify-center items-center">
      <h1 className="text-xl font-semibold border-r-1 border-gray py-8 pr-16 mr-16">404</h1>
      <h2 className="text-lg">This page could not be found.</h2>
    </div>
  );
}
